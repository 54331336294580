<div
    class="bazis-dateplate"
    *transloco="let t"
>
    <span
        *ngIf="titleKey"
        [class.bazis-text_action]="colorIsAction"
    >
        {{ t(titleKey, titleParams) }}
        <span
            *ngIf="count"
            class="bh-text-nowrap"
        >
            {{ t('count.dates', { pluralType: count | plural, count: this.count }) }}
        </span>
        <span
            *ngIf="date"
            class="bh-text-nowrap"
        >
            {{ date | localDate: 'longDateMonth' }}
        </span>
        <br />
    </span>
    <span
        class="bazis-dateplate__time"
        [class.bazis-dateplate__time_small]="size === 'xxs'"
        *ngIf="time !== null"
    >
        <bazis-icon
            [size]="size"
            name="clock"
            *ngIf="!hideTime"
            class="bazis-text_secondary"
        ></bazis-icon>
        <span *ngIf="time">
            {{ time }}
        </span>

        <span *ngIf="timeStart">
            {{ timeStart }}<ng-container *ngIf="timeEnd">&ndash;{{ timeEnd }}</ng-container>
        </span>

        <span *ngIf="!timeStart && !timeEnd && !time && !hideTime">
            {{ t('title.noTime') }}
        </span>

        <bazis-icon
            *ngIf="hasCheck"
            name="check"
            color="success"
            class="bh-margin-start"
            coef="2"
        ></bazis-icon>
    </span>
</div>
