import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntData } from '@bazis/shared/models/srv.types';

@Component({
    selector: 'bazis-document-accordeon',
    templateUrl: './document-accordeon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentAccordeonComponent implements OnDestroy, OnInit {
    @Input()
    document: EntData;

    @Input()
    isOpen = false;

    isPdf = false;

    nonPdfUrl = '';

    openDropdownMenu = false;

    constructor() {}

    ngOnInit(): void {
        this.isPdf = this.document.$snapshot.file_upload_url.includes('.pdf');
        this.nonPdfUrl = `https://docs.google.com/gview?url=https://dev.sw.big3.ru${this.document.$snapshot.file_upload_url}&embedded=true`;
    }

    ngOnDestroy(): void {}

    onloadFrame(frame) {
        setTimeout(() => {
            if (frame.contentWindow.document.getElementsByTagName('img')[0]) {
                frame.contentWindow.document.getElementsByTagName('img')[0].style.width = '100%';
            }
        }, 50);
    }
}
