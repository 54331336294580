import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Directives
import { ScrollDirective } from '@bazis/shared/directives/scroll.directive';
import { ClickOutsideDirective } from '@bazis/shared/directives/click-outside.directive';
import { ModalDirective } from '@bazis/shared/directives/modal.directive';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { BackgroundDirective } from '@bazis/shared/directives/background.directive';
import { IfPropertyDirective } from '@bazis/shared/directives/if-property.directive';
import { IfControlDirective } from '@bazis/shared/directives/if-control.directive';
import { StickyDirective } from '@bazis/shared/directives/sticky.directive';
import { TextMediumDirective } from '@bazis/shared/directives/text-medium.directive';
import { CoefDirective } from '@bazis/shared/directives/coef.directive';
import { InView } from '@bazis/shared/directives/in-view.directive';
import { FixNumberInputDirective } from '@bazis/shared/directives/fix-number-input.directive';
import { LocalRouterLinkDirective } from '@bazis/shared/directives/local-router-link.directive';
import { SwipeDirective } from '@bazis/shared/directives/swipe.directive';
import { AnchorDirective } from '@bazis/shared/directives/anchor.directive';

// Pipes
import { EntityPipe } from '@bazis/shared/pipes/entity.pipe';
import { EntityListPipe } from '@bazis/shared/pipes/entity-list.pipe';
import { FileSizePipe } from '@bazis/shared/pipes/file-size.pipe';
import { SafePipe } from '@bazis/shared/pipes/safe.pipe';
import { ReplaceCommaPipe } from '@bazis/shared/pipes/replace-comma.pipe';
import { StatusPipe } from '@bazis/shared/pipes/status.pipe';
import { TransitPipe } from '@bazis/shared/pipes/transit.pipe';
import { EnumPipe } from '@bazis/shared/pipes/enum.pipe';
import { PluralPipe } from '@bazis/shared/pipes/plural.pipe';
import { DateDiffPipe } from '@bazis/shared/pipes/date-diff.pipe';
import { OrganizationPipe } from '@bazis/shared/pipes/organization.pipe';
import { LocalDatePipe } from '@bazis/shared/pipes/local-date.pipe';
import { LocalNumberPipe } from '@bazis/shared/pipes/local-number.pipe';

// Modules
import { DatepickerModule } from '@bazis/shared/components/datepicker/datepicker.module';
import { TimepickerModule } from '@bazis/shared/components/timepicker/timepicker.module';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { ColModule } from '@bazis/shared/components/grid/col/col.module';
import { GridModule } from '@bazis/shared/components/grid/grid/grid.module';
import { RowModule } from '@bazis/shared/components/grid/row/row.module';
import { ToggleModule } from '@bazis/shared/components/toggle/toggle.module';
import { TabsModule } from '@bazis/shared/components/tabs/tabs.module';
import { ChipModule } from '@bazis/shared/components/chip/chip.module';
import { BadgeModule } from '@bazis/shared/components/badge/badge.module';
import { ButtonModule } from '@bazis/shared/components/button/button.module';
import { LogoModule } from '@bazis/shared/components/logo/logo.module';
import { LinkModule } from '@bazis/shared/components/link/link.module';
import { TooltipModule } from '@bazis/shared/components/tooltip/tooltip.module';
import { ToolbarModule } from '@bazis/shared/components/toolbar/toolbar.module';
import { StepModule } from '@bazis/shared/components/step/step.module';
import { AccordionModule } from '@bazis/shared/components/accordion/accordion.module';
import { TripLineModule } from '@bazis/shared/components/trip-line/trip-line.module';
import { SkeletonModule } from '@bazis/shared/components/skeleton/skeleton.module';
import { RangeModule } from '@bazis/shared/components/range/range.module';
import { ThumbnailModule } from '@bazis/shared/components/thumbnail/thumbnail.module';
import { CardValueModule } from '@bazis/shared/components/card-value/card-value.module';
import { SignStatusModule } from '@bazis/shared/components/sign-status/sign-status.module';
import { LayoutPressFooterModule } from '@bazis/shared/components/layout-press-footer/layout-press-footer.module';
import { InterrogationModule } from '@bazis/shared/components/interrogation/interrogation.module';
import { TotopModule } from '@bazis/shared/components/totop/totop.module';

// Components
import { InfiniteListComponent } from '@bazis/shared/components/infinite-list.component';
import { InfiniteListSortSelectedComponent } from '@bazis/shared/components/infinite-list-sort-selected.component';
import { BreadcrumbsComponent } from '@bazis/shared/components/breadcrumbs/breadcrumbs.component';
import { AlertComponent } from '@bazis/shared/components/alert/alert.component';
import { ToastComponent } from '@bazis/shared/components/toast/toast.component';
import { ModalComponent } from '@bazis/shared/components/modal/modal.component';
import { GlobalLoaderComponent } from '@bazis/shared/components/loaders/global-loader.component';
import { DefaultLoaderComponent } from '@bazis/shared/components/loaders/default-loader.component';
import { TestModalComponent } from '@bazis/shared/components/modals/test-modal.component';
import { ModalsComponent } from '@bazis/shared/components/modal/modals.component';
import { CoordinatesComponent } from '@bazis/shared/components/coordinates/coordinates.component';
import { EntityStatusListComponent } from '@bazis/shared/components/entity-status-list.component';
import { ScheduleTimePipe } from '@bazis/shared/pipes/schedule-time.pipe';
import { GalleryModalComponent } from '@bazis/shared/components/modals/gallery-modal.component';
import { VehicleNumberComponent } from '@bazis/shared/components/vehicle-number/vehicle-number.component';
import { GallerySliderComponent } from '@bazis/shared/components/gallery/gallery-slider.component';
import { GalleryComponent } from '@bazis/shared/components/gallery/gallery.component';
import { StatusComponent } from '@bazis/shared/components/status/status.component';
import { FileInlineComponent } from '@bazis/shared/components/file/file-inline.component';
import { CardContactComponent } from '@bazis/shared/components/card-contact/card-contact.component';
import { DateplateComponent } from '@bazis/shared/components/dateplate/dateplate.component';
import { GallerySingleSliderComponent } from '@bazis/shared/components/gallery/gallery-single-slider.component';
import { GallerySkeletonComponent } from '@bazis/shared/components/gallery/gallery-skeleton.component';
import { EntityStatusListSkeletonComponent } from '@bazis/shared/components/entity-status-list-skeleton.component';
import { EmptyComponent } from '@bazis/shared/components/empty/empty.component';
import { CookieComponent } from '@bazis/shared/components/cookie/cookie.component';
import { TabsSliderComponent } from '@bazis/shared/components/tabs-slider/tabs-slider.component';
import { SimpleModalComponent } from '@bazis/shared/components/modals/simple-modal.component';
import { SliderComponent } from '@bazis/shared/components/slider/slider.component';
import { DummyMobileComponent } from '@bazis/shared/components/dummy-mobile/dummy-mobile.component';
import { DummyModalComponent } from '@bazis/shared/components/modals/dummy-modal.component';
import { LoadMoreListComponent } from '@bazis/shared/components/loadmore-list.component';
import { TimelineComponent } from '@bazis/shared/components/timeline/timeline.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ChartModule } from 'angular-highcharts';
import { DocumentAccordeonComponent } from '@bazis/shared/components/document-accordeon/document-accordeon.component';
import { PhonePipe } from '@bazis/shared/pipes/phone.pipe';
import { SignedFileInlineComponent } from '@bazis/shared/components/file/signed-file-inline.component';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

const DECLARATIONS = [
    EntityPipe,
    EntityListPipe,
    FileSizePipe,
    SafePipe,
    ReplaceCommaPipe,
    ScheduleTimePipe,
    StatusPipe,
    TransitPipe,
    EnumPipe,
    PluralPipe,
    DateDiffPipe,
    OrganizationPipe,
    LocalDatePipe,
    LocalNumberPipe,
    PhonePipe,

    ScrollDirective,
    ColorDirective,
    ModalDirective,
    ClickOutsideDirective,
    BackgroundDirective,
    IfPropertyDirective,
    IfControlDirective,
    StickyDirective,
    TextMediumDirective,
    CoefDirective,
    InView,
    FixNumberInputDirective,
    LocalRouterLinkDirective,
    SwipeDirective,
    AnchorDirective,

    InfiniteListComponent,
    InfiniteListSortSelectedComponent,
    LoadMoreListComponent,
    BreadcrumbsComponent,
    CookieComponent,
    CoordinatesComponent,
    EntityStatusListComponent,
    EntityStatusListSkeletonComponent,
    VehicleNumberComponent,
    SliderComponent,
    GallerySliderComponent,
    GallerySingleSliderComponent,
    TimelineComponent,

    AlertComponent,
    ToastComponent,
    ModalComponent,
    ModalsComponent,
    GlobalLoaderComponent,
    DefaultLoaderComponent,
    EmptyComponent,

    TestModalComponent,
    GalleryModalComponent,
    GalleryComponent,
    GallerySkeletonComponent,
    StatusComponent,
    FileInlineComponent,
    SignedFileInlineComponent,
    CardContactComponent,
    DateplateComponent,
    SimpleModalComponent,
    TabsSliderComponent,
    DocumentAccordeonComponent,

    DummyMobileComponent,
    DummyModalComponent,
];

const WEB_COMPONENT_MODULES = [
    GridModule,
    RowModule,
    ColModule,
    IconModule,
    ToggleModule,
    TabsModule,
    ChipModule,
    BadgeModule,
    ButtonModule,
    LogoModule,
    LinkModule,
    DatepickerModule,
    TimepickerModule,
    TooltipModule,
    ToolbarModule,
    StepModule,
    AccordionModule,
    TripLineModule,
    SkeletonModule,
    RangeModule,
    ThumbnailModule,
    CardValueModule,
    SignStatusModule,
    LayoutPressFooterModule,
    InterrogationModule,
    TotopModule,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslocoModule,
        NgxSliderModule,
        ChartModule,
        WEB_COMPONENT_MODULES,
    ],
    exports: [...DECLARATIONS, ...WEB_COMPONENT_MODULES],
    declarations: [...DECLARATIONS],
})
export class BazisSharedModule {}
