<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            list: list$ | async
        } as data"
    >
        <ng-container *ngFor="let item of data.list; let i = index; trackBy: trackById">
            <ng-template
                [ngTemplateOutlet]="itemTpl"
                [ngTemplateOutletContext]="{ item: item }"
            ></ng-template>
        </ng-container>

        <bazis-button
            *ngIf="currentPortionLoad < countPortionLoad || countListAll > limit"
            fill="outline"
            color="action"
            class="bh-margin-top"
            (click)="needNextPage$.next(true)"
        >
            <bazis-icon
                slot="start"
                name="rotate-right"
            ></bazis-icon>
            {{ t('action.loadMore', { count: limit }) }}
        </bazis-button>
    </ng-container>
</ng-container>
