<div
    [class.bazis-modal]="!modal.options.styleAlert"
    [ngClass]="[
        modal.options.cssClass,
        isScrollingContent ? 'bazis-modal--scrolling' : '',
        modal.options.modalType === 'fullscreen' ? 'bazis-container' : '',
        modal.options.modalType
    ]"
>

    <bazis-button fill="clear"
        class="bazis-modal__close"
        *ngIf="modal.options.hasCloseIcon"
        (click)="close()"
    >
        <bazis-icon class="bh-hide-sm-down" name="cross" slot="icon-only"></bazis-icon>
        <bazis-icon class="bh-hide-sm-up" name="arrow-left" slot="icon-only"></bazis-icon>
    </bazis-button>

    <ng-template modal></ng-template>

    <!-- <div *ngIf="!modal.options.hasHeaderInner">
        <div class="bazis-modal__header"
            *ngIf="modal.options.title"
        >
            <h1>{{ modal.options.title }}</h1>
        </div>

        <div class="bazis-modal__content" (scroll)="onContentScroll($event)">
            <ng-template modal></ng-template>
        </div>

        <div class="bazis-modal__footer"></div>
    </div> -->
</div>
<div class="bazis-backdrop"
    *ngIf="modal.options.hasBackdrop"
    (click)="closeFromBackdrop()"
></div>
