import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[coef]',
})
export class CoefDirective {
    constructor() {}

    @Input() coef: number;

    @HostBinding('style.--bazis-coef') get coefVariables() {
        return this.coef;
    }
}
