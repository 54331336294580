<ng-container *ngIf="{ list: list$ | async } as data"></ng-container>
<div class="bazis-slider">
    <div class="bazis-slider__wrapper">
        <div
            class="bazis-slider__content"
            #sliderContainer
            [style.transform]="'translateX(' + shift + 'px)'"
            swipe
            (swipeLeft)="slideEnd()"
            (swipeRight)="slideStart()"
        >
            <ng-content></ng-content>
        </div>
    </div>

    <div
        *ngIf="containerWidth < allSlidesWidth"
        class="bazis-slider__controls"
    >
        <bazis-button
            [fill]="typeButton"
            color="action"
            [size]="sizeButton"
            class="bazis-slider__prev"
            [disabled]="shift === 0"
            (click)="slideStart()"
        >
            <bazis-icon
                slot="icon-only"
                [icon]="iconPrev"
            ></bazis-icon>
        </bazis-button>

        <bazis-button
            [fill]="typeButton"
            color="action"
            [size]="sizeButton"
            class="bazis-slider__next"
            [disabled]="isEndSlider"
            (click)="slideEnd()"
        >
            <bazis-icon
                slot="icon-only"
                [icon]="iconNext"
            ></bazis-icon>
        </bazis-button>
    </div>
</div>
