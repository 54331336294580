import { Injectable } from '@angular/core';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { combineLatest, finalize, from, Observable, of, shareReplay, switchMap } from 'rxjs';
import { SrvService } from '@bazis/shared/services/srv.service';
import { StorageService } from '@bazis/shared/services/storage.service';
import {
    EntData,
    EntList,
    EntSchema,
    SchemaType,
    SimpleData,
} from '@bazis/shared/models/srv.types';
import { ModalService } from '@bazis/shared/services/modal.service';
import { TransitModalComponent } from '@form/components/helpers/transit-modal.component';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { buildFilterStr } from '@bazis/utils';

@Injectable({
    providedIn: 'root',
})
export class EntityService {
    constructor(
        private srv: SrvService,
        private storage: StorageService,
        private modalService: ModalService,
    ) {}

    private _getRequests = {};

    private _getSchemaRequests = {};

    // doNotInitLoad - только если в логике 100% будет одновременно вызываться forceLoad
    getEntity$(
        entityType: string,
        id: string = '',
        forceLoad = false,
        doNotInitLoad = false,
        include: string[] = [],
    ): Observable<EntData> {
        // проверяем существование сущности
        const isExistedItem = this.storage.isExistedItem(entityType, id);
        // если ее нет или надо обязательно перезапросить или сейчас в процессе запроса
        const path = `${entityType}-${id}`;
        if (this._getRequests[path] && !forceLoad) {
            return this._getRequests[path];
        }

        if (
            (!isExistedItem || forceLoad || !this.storage.getItem(entityType, id)) &&
            !doNotInitLoad
        ) {
            // создаем в storage item$, в который после получения сущности ее отправим
            this.storage.getItem$(entityType, id);

            // если это обычная сущность, то просто ее получаем
            // сохраняем запрос, чтобы не "потерять сущность при отписке async", если запрос еще не был к моменту отписки завершен
            this._getRequests[path] = this.srv.fetchEntity$(entityType, id, include).pipe(
                mergeMap((r) => {
                    // сохраняем в storage
                    if (r.included && r.included.length > 0) {
                        r.included.forEach((includedItem) => {
                            this.storage.getItem$(includedItem.type, includedItem.id);
                            this.storage.setItem(includedItem.type, includedItem, includedItem.id);
                        });
                        delete r.included;
                    }
                    this.storage.setItem(entityType, r, id);
                    delete this._getRequests[path];
                    return this.storage.getItem$(entityType, id);
                }),
                tap((v) => {
                    delete this._getRequests[path];
                }),
                finalize(() => {
                    // если в момент finalize нет данных по объекту, то удаляем и информацию о запросе, и данные в storage
                    if (!this.storage.getItem(entityType, id)) {
                        this.storage.removeItem('entity', entityType, id);
                        delete this._getRequests[path];
                    }
                }),
                shareReplay(1),
            );

            // + возвращаем этот запрос, если
            return this._getRequests[path];
        }

        // если уже есть сущность, значит, просто ее возвращаем
        return this.storage.getItem$(entityType, id);
    }

    getOrganizationEntity$(orgOwnerId: string, forceLoad = false): Observable<EntData> {
        // проверяем существование сущности
        const entityType = 'organization.organization_info';
        const id = orgOwnerId;
        const isExistedItem = this.storage.isExistedItem(entityType, orgOwnerId);
        // если ее нет или надо обязательно перезапросить или сейчас в процессе запроса
        const path = `${entityType}-${id}`;
        if (this._getRequests[path] && !forceLoad) {
            return this._getRequests[path];
        }

        if (!isExistedItem || forceLoad) {
            // создаем в storage item$, в который после получения сущности ее отправим
            this.storage.getItem$(entityType, id);

            // если это обычная сущность, то просто ее получаем
            // сохраняем запрос, чтобы не "потерять сущность при отписке async", если запрос еще не был к моменту отписки завершен
            this._getRequests[path] = this.getEntityList$(
                'organization.organization_info',
                '',
                '',
                {
                    filter: buildFilterStr({ org_owner: id }),
                },
                0,
                1,
            ).pipe(
                map((v) => (v.list && v.list.length > 0 ? v.list[0] : null)),
                mergeMap((r) => {
                    this.storage.setItem(entityType, r, id);
                    delete this._getRequests[path];
                    return this.storage.getItem$(entityType, id);
                }),
                tap((v) => {
                    delete this._getRequests[path];
                }),
                finalize(() => {
                    if (!this.storage.getItem(entityType, id)) {
                        this.storage.removeItem('entity', entityType, id);
                        delete this._getRequests[path];
                    }
                }),
                shareReplay(), // upd correctly in notifications
            );
            return this._getRequests[path];
        }

        // если уже есть сущность, значит, просто ее возвращаем
        return this.storage.getItem$(entityType, id);
    }

    // sync method to get entity from storage
    getEntity(entityType: string, id: string = ''): EntData {
        return this.storage.getItem(entityType, id);
    }

    setEntity(entity) {
        this.storage.getItem$(entity.type, entity.id);
        this.storage.setItem(entity.type, entity, entity.id);
    }

    deleteEntity$(entityType: string, id: string = '') {
        return this.srv.deleteEntity$(entityType, id).pipe(
            tap(() => {
                this.storage.removeItem('entity', entityType, id);
            }),
            take(1),
        );
    }

    setFavourite$(entityType: string, id: string, value: boolean) {
        return this.srv.setPriority$(entityType, id, value, 'favorites').pipe(
            tap(() => {
                this.storage.patchItem(entityType, id, { is_favorites: value });
            }),
            take(1),
        );
    }

    setHidden$(entityType: string, id: string, value: boolean) {
        return this.srv.setPriority$(entityType, id, value, 'hidden').pipe(
            tap(() => {
                this.storage.patchItem(entityType, id, { is_hidden: value });
            }),
            take(1),
        );
    }

    // sync method to get entity from storage
    getSchema(schemaType: SchemaType, entityType: string, id: string = ''): EntSchema {
        return this.storage.getSchema(schemaType, entityType, id);
    }

    getSchema$(
        schemaType: SchemaType,
        entityType: string,
        id: string = '',
        forceLoad = false,
        include: string[] = [],
    ): Observable<EntSchema> {
        // проверяем существование сущности
        const isExistedSchema = this.storage.isExistedSchema(schemaType, entityType, id);
        const path = `${schemaType}-${entityType}-${id}`;
        if (this._getSchemaRequests[path] && !forceLoad) {
            return this._getSchemaRequests[path];
        }
        // если ее нет или надо обязательно перезапросить
        if (!isExistedSchema || forceLoad) {
            // создаем в storage item$, в который после получения сущности ее отправим
            this.storage.getSchema$(schemaType, entityType, id);

            // получаем
            this._getSchemaRequests[path] = this.srv
                .fetchSchema$(schemaType, entityType, id, include)
                .pipe(
                    mergeMap((r: EntSchema) => {
                        // сохраняем в storage
                        if (r.included) {
                            r.included.forEach((schema) => {
                                if (schema.schemaType === 'schema_create' || schema.id) {
                                    this.storage.getSchema$(
                                        schema.schemaType,
                                        schema.entityType,
                                        schema.id,
                                    );
                                    this.storage.setSchema(
                                        schema.schemaType,
                                        schema.entityType,
                                        schema,
                                        schema.id,
                                    );
                                } else {
                                    this.storage.getSchemaFromParent$(
                                        schema.schemaType,
                                        schema.entityType,
                                        entityType,
                                        id,
                                    );
                                    this.storage.setSchemaFromParent(
                                        schema.schemaType,
                                        schema.entityType,
                                        entityType,
                                        id,
                                        schema,
                                    );
                                }
                            });
                            delete r.included;
                        }
                        this.storage.setSchema(schemaType, entityType, r, id);
                        return this.storage.getSchema$(schemaType, entityType, id);
                    }),
                    tap((v) => {
                        delete this._getSchemaRequests[path];
                    }),
                    finalize(() => {
                        // если в момент finalize нет данных по объекту, то удаляем и информацию о запросе, и данные в storage
                        if (!this.storage.getSchema(schemaType, entityType, id)) {
                            this.storage.removeItem('schema', entityType, id, schemaType);
                            delete this._getSchemaRequests[path];
                        }
                    }),
                    shareReplay(1),
                );

            return this._getSchemaRequests[path];
        }

        // если уже есть сущность, значит, просто ее возвращаем
        return this.storage.getSchema$(schemaType, entityType, id);
    }

    getParentSchema$(
        schemaType: SchemaType,
        entityType: string,
        id: string,
        parentType: string,
        parentId: string,
    ): Observable<EntSchema> {
        // проверяем существование схемы
        const isExistedSchema = this.storage.isExistedSchemaFromParent(
            schemaType,
            entityType,
            parentType,
            parentId,
        );

        if (isExistedSchema) {
            return this.storage.getSchemaFromParent$(schemaType, entityType, parentType, parentId);
        }

        return this.getSchema$(schemaType, entityType, id);
    }

    getParentSchema(
        schemaType: SchemaType,
        entityType: string,
        id: string,
        parentType: string,
        parentId: string,
    ): EntSchema {
        // проверяем существование схемы
        const isExistedSchema = this.storage.isExistedSchemaFromParent(
            schemaType,
            entityType,
            parentType,
            parentId,
        );

        if (isExistedSchema) {
            return this.storage.getSchemaFromParent(schemaType, entityType, parentType, parentId);
        }

        return this.getSchema(schemaType, entityType, id);
    }

    getAllEntitiesList$(entityType: string, forceLoad = false): Observable<EntList> {
        // проверяем существование списка
        const isExistedList = this.storage.isExistedList(entityType);

        // если списка нет или его надо в обязательном порядке перегрузить
        if (!isExistedList || forceLoad) {
            this.storage.getList$(entityType);
            this._getRequests[entityType] = this.srv.fetchAllEntities$(entityType).pipe(
                mergeMap((r) => {
                    // сохраняем в storage
                    this.storage.setList(entityType, r);
                    return this.storage.getList$(entityType);
                }),
                tap(() => {
                    delete this._getRequests[entityType];
                }),
                shareReplay(1),
            );

            return this._getRequests[entityType];
        }

        // если уже есть список, значит, просто его возвращаем
        return this.storage.getList$(entityType);
    }

    // не использует storage - для построения динамических списков с подскроллом, зависящих от внешних параметров
    getEntityList$(
        entityType: string,
        suffix: string = '',
        search = '',
        params: any = null,
        offset = 0,
        limit = 20,
        meta: string[] = [],
    ): Observable<EntList> {
        params = { ...params };
        if (params.sort === null) {
            delete params.sort;
        } else if (!params.sort) {
            params.sort = '-dt_created';
        }

        return this.srv.fetchPortion$(entityType, suffix, offset, limit, search, params, meta);
    }

    // полученные в листе данные сохраняем как отдельные сущности и их возвращаем
    // (!!! следить самостоятельно за мета, листы не всю возвращают)
    getEntityListAsSeparateItems$(
        entityType: string,
        suffix: string = '',
        search = '',
        params: any = null,
        offset = 0,
        limit = 20,
        meta: string[] = [],
    ): Observable<EntData[]> {
        return this.getEntityList$(entityType, suffix, search, params, offset, limit, meta).pipe(
            tap((list) => {
                list.list.forEach((entity) => {
                    this.setEntity(entity);
                });
            }),
            switchMap((list) =>
                combineLatest(list.list.map((v) => this.getEntity$(entityType, v.id, false, true))),
            ),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    getListsCounts(
        settings: { entityType: string; filters: any; filterParams?: any; params?: any }[],
    ) {
        return this.srv
            .countRequest(settings)
            .pipe(map((responses) => responses.map((response) => response.count || 0)));
    }

    toEntityItem(data: SimpleData | EntData): EntData {
        return data.$snapshot
            ? (data as EntData)
            : {
                  id: data.id,
                  $snapshot: {
                      ...data,
                  },
                  type: 'any',
              };
    }

    toEntitiesList(array: SimpleData[] | EntData[]): EntList {
        return {
            list: array.map((arrayItem) => this.toEntityItem(arrayItem)),
        };
    }

    _buildTransitData$(
        settings: {
            entityType?: string;
            entityId?: string;
            url?: string;
            transit: string;
            payload?: any;
            hint?: string;
            modalComponent?;
        }[],
        transitSettings = [],
    ): Observable<{ status: string; transitSettings: any }> {
        for (let i = transitSettings.length; i < settings.length; i++) {
            const transitItem = settings[i];

            if (transitItem.payload || transitItem.hint) {
                if (!transitItem.modalComponent) {
                    transitItem.modalComponent = TransitModalComponent;
                }
                const modal = this.modalService.create({
                    component: transitItem.modalComponent,
                    componentProperties: {
                        payloadMap: transitItem.payload,
                        hint: transitItem.hint,
                        transit: transitItem.transit,
                    },
                    hasCloseIcon: false,
                    styleAlert: true,
                });

                return from(modal.onDidDismiss()).pipe(
                    switchMap((payloadBody) => {
                        if (payloadBody === null) {
                            return of({
                                status: 'cancel',
                                transitSettings: null,
                            });
                        }
                        transitSettings.push({
                            entityType: transitItem.entityType,
                            entityId: transitItem.entityId,
                            url: transitItem.url,
                            transitParams: {
                                transit: transitItem.transit,
                                payload: payloadBody,
                            },
                        });
                        return this._buildTransitData$(settings, transitSettings);
                    }),
                    take(1),
                );
            }

            transitSettings.push({
                entityType: transitItem.entityType,
                entityId: transitItem.entityId,
                url: transitItem.url,
                transitParams: {
                    transit: transitItem.transit,
                    payload: null,
                },
            });
        }

        if (transitSettings.length === settings.length) {
            return of({
                status: 'ready',
                transitSettings,
            });
        }
    }

    transitEntity$(
        settings: {
            entityType?: string;
            entityId?: string;
            url?: string;
            transit: string;
            payload?: any;
            hint?: string;
            modalComponent?: any;
        }[],
    ) {
        return this._buildTransitData$(settings).pipe(
            filter((response) => response.status === 'cancel' || response.status === 'ready'),
            switchMap((response) =>
                response.status === 'cancel'
                    ? of(null)
                    : this.srv.transitEntity$(response.transitSettings),
            ),
        );
    }

    changeEntityProperty$(
        entityType: string,
        entityId: string,
        property: { type?: string; value: any; name: string },
    ): Observable<EntData> {
        const request: any = {
            action: 'change',
            id: entityId,
            type: entityType,
            attributes: {},
            relationships: {},
        };
        if (property.type) {
            const data = Array.isArray(property.value)
                ? property.value.map((value) => ({ id: value, type: property.type }))
                : {
                      id: property.value,
                      type: property.type,
                  };
            request.relationships[property.name] = { data };
        } else {
            request.attributes[property.name] = property.value;
        }
        return this.srv.saveEntity$(entityType, entityId, { data: request });
    }

    changeEntityProperties$(
        entityType: string,
        entityId: string,
        properties: { type?: string; value: any; name: string }[],
        updateEntityInStorage = true,
    ): Observable<EntData> {
        const request: any = {
            action: 'change',
            id: entityId,
            type: entityType,
            attributes: {},
            relationships: {},
        };
        properties.forEach((property) => {
            if (property.type) {
                const data = Array.isArray(property.value)
                    ? property.value.map((value) => ({ id: value, type: property.type }))
                    : {
                          id: property.value,
                          type: property.type,
                      };
                request.relationships[property.name] = { data };
            } else {
                request.attributes[property.name] = property.value;
            }
        });

        return this.srv.saveEntity$(entityType, entityId, { data: request }).pipe(
            map((r) => {
                if (updateEntityInStorage) this.storage.setItem(entityType, r, entityId);
                return r;
            }),
        );
    }
}
