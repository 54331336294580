<div
    class="bazis-infinite-scroll-content"
    scroll
    (scrollToBottom)="needNextPage$.next(true)"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            list: list$ | async,
            listExludeSelected: listExludeSelected$ | async
        } as data"
    >
        <ng-container *ngIf="data.list && isMultiple && selectedMap.size > 0">
            <div class="bazis-item bazis-item_group">
                <div class="bh-flex bh-justify-content-between">
                    <span (click)="toggleSelectedGroup()">
                        {{ t('title.selected') }} ( {{ selectedMap.size }} )
                        <bazis-icon
                            size="s"
                            [name]="showSelectedGroup ? 'angle-small-up' : 'angle-small-down'"
                        ></bazis-icon>
                    </span>
                    <bazis-link
                        borderStyle="none"
                        fontWeight="500"
                        class="bh-hide-lg-up bazis-text_minor"
                        color="danger"
                        (click)="clearAll()"
                    >
                        {{ t('filter.clear') }}
                    </bazis-link>
                </div>
            </div>
            <ng-container *ngIf="showSelectedGroup">
                <ng-container
                    *ngFor="let item of selectedList$ | async; let i = index; trackBy: trackById"
                >
                    <ng-template
                        [ngTemplateOutlet]="itemTpl"
                        [ngTemplateOutletContext]="{ item: item }"
                    ></ng-template>
                </ng-container>
            </ng-container>
        </ng-container>

        <div
            *ngIf="data.list && isMultiple"
            class="bazis-item bazis-item_group"
            (click)="toggleSearchGroup()"
        >
            <span>
                {{ t('title.search') }}
                <bazis-icon
                    size="s"
                    [name]="showSearchGroup ? 'angle-small-up' : 'angle-small-down'"
                ></bazis-icon>
            </span>
        </div>
        <ng-container *ngIf="showSearchGroup">
            <ng-container
                *ngFor="
                    let item of data.listExludeSelected;
                    let i = index;
                    trackBy: trackById;
                "
            >
                <ng-template
                    [ngTemplateOutlet]="itemTpl"
                    [ngTemplateOutletContext]="{ item: item }"
                ></ng-template>
            </ng-container>
            <ng-container *ngIf="!isMultiple && !data.listExludeSelected?.length">
                <ng-container
                    *ngFor="
                        let item of data.list;
                        let i = index;
                        trackBy: trackById;
                    "
                >
                    <ng-template
                        [ngTemplateOutlet]="itemTpl"
                        [ngTemplateOutletContext]="{ item: item }"
                    ></ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
        <div
            class="bazis-item bh-events-none"
            *ngIf="data.list?.length === 0 || data.listExludeSelected?.length === 0"
        >
            {{ t(emptyListKey || 'title.emptyList') }}
        </div>
        <bazis-button
            *ngIf="data.list && hasApplyBtn"
            color="action"
            class="bh-block"
            [disabled]="selectedMap.size === 0"
            (click)="applyAll()"
        >
            {{ t('action.showCount', { count: selectedMap.size }) }}
        </bazis-button>
    </ng-container>
</div>
